import TextTitle from "../component/TextTitle";
import { useState, useRef, useEffect } from "react";
import DivComponent from "../component/DivComponent";
const boldText = (string) => {
  return <span style={{ fontWeight: 700 }}>{string}</span>;
};
const renderImg = (imgURL) => {
  return <img width={"60%"} src={`/img/${imgURL}.png`} />;
};

const ProgressBar = ({ currentStep, totalSteps, handleStepClick }) => {
  return (
    <div
      style={{
        position: "sticky",
        display: "flex",
        justifyContent: "space-between",
        background: "white",
        top: 0,
        zIndex: 10,
      }}
    >
      {[...Array(totalSteps)].map((_, index) => (
        <>
          <div
            key={index}
            onClick={() => handleStepClick(index + 1)}
            style={{
              borderRadius: "0px 10px 10px 0px",
              width: "100%",
              height: "6px",
              background: index < currentStep ? "#FFC028" : "#EEE",
              display: "flex",
              color: index < currentStep ? "#FFC028" : "#DEDEDE",
            }}
          >
            <span
              style={{
                background: "white",
                position: "relative",
                width: "100%",
                top: 6,
                height: 50,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              {" "}
              0{index + 1}
            </span>
          </div>
        </>
      ))}
    </div>
  );
};

function ExchangeInfo() {
  const [currentStep, setCurrentStep] = useState(1);
  const stepRef = useRef([]);
  const totalSteps = 7;

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      for (let i = 0; i < stepRef.current.length; i++) {
        const element = stepRef.current[i];
        const elementHeight =
          i === 6 ? element.offsetTop : element.offsetTop + 400;

        if (element && scrollPosition >= elementHeight) {
          setCurrentStep(i + 1);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const scrollToStep = (step) => {
    const element = stepRef.current[step - 1];
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <div>
      <ProgressBar
        currentStep={currentStep}
        totalSteps={totalSteps}
        handleStepClick={scrollToStep}
      />

      <DivComponent ref={(el) => (stepRef.current[0] = el)}>
        <TextTitle string={"01 환전예약"} />
        <span>
          {boldText("환전 예약하기 ")}
          또는 {boldText("지점찾기")}에서 환전예약을 진행해주세요.
        </span>
        {renderImg("exchange1")}
      </DivComponent>
      <DivComponent ref={(el) => (stepRef.current[1] = el)}>
        {" "}
        <TextTitle string={"02 키오스크 지점선택"} />
        <span>
          지점리스트에서 환전예약 신청하실 {boldText("지점을 선택")}해주세요.
        </span>
        {renderImg("exchange2")}
      </DivComponent>
      <DivComponent ref={(el) => (stepRef.current[2] = el)}>
        {" "}
        <TextTitle string={"03 환전예약 날짜 선택"} />
        <span>
          환전예약 신청하실 {boldText("날짜를 선택")}후 {boldText("확인완료")}를
          체크해주세요{" "}
        </span>
        {renderImg("exchange3")}
      </DivComponent>
      <DivComponent ref={(el) => (stepRef.current[3] = el)}>
        {" "}
        <TextTitle string={"04 환전예약 수령 금액 선택"} />
        <span>
          수령하실 {boldText("환전 금액")}과 환전 하실{" "}
          {boldText("국가 통화를 선택")}해주세요.
        </span>
        {renderImg("exchange4")}
      </DivComponent>
      <DivComponent ref={(el) => (stepRef.current[4] = el)}>
        {" "}
        <TextTitle string={"05 환전예약 신청 확인"} />
        <span
          style={{
            textAlign: "center",
          }}
        >
          포인트 를사용하여 예약신청을 하실수 있으며,
          <br />
          {boldText("환전 예약신청 금액 ")}과 {boldText("입금 기한을 확인")}
          해주세요.
        </span>
        {renderImg("exchange5")}
      </DivComponent>
      <DivComponent ref={(el) => (stepRef.current[5] = el)}>
        <TextTitle string={"06 환전예약 신청 완료 확인"} />
        <span style={{ textAlign: "center" }}>
          수령장소, 날짜, 시간, {boldText("입금계좌")}를 확인후
          <br />
          {boldText("반드시 입금을 진행")}해주세요.
        </span>
        {renderImg("exchange6")}
      </DivComponent>
      <DivComponent ref={(el) => (stepRef.current[6] = el)}>
        <TextTitle string={"07 환전예약 신청 관리"} />
        <span style={{ textAlign: "center" }}>
          예약신청 관리에서 신청내역과 취소내역,
          <br /> 수령위치를 확인 해주세요.
        </span>{" "}
        {renderImg("exchange7")}
        <div style={{ height: 150 }}></div>
      </DivComponent>
    </div>
  );
}

export default ExchangeInfo;
