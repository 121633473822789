const ProgressBar = ({ currentStep, totalSteps, handleStepClick }) => {
  return (
    <div
      style={{
        position: "sticky",
        display: "flex",
        justifyContent: "space-between",
        background: "white",
        top: 0,
        zIndex: 10,
      }}
    >
      {[...Array(totalSteps)].map((_, index) => (
        <>
          <div
            key={index}
            onClick={() => handleStepClick(index + 1)}
            style={{
              borderRadius: "0px 10px 10px 0px",
              width: "100%",
              height: "6px",
              background: index < currentStep ? "#FFC028" : "#EEE",
              display: "flex",
              color: index < currentStep ? "#FFC028" : "#DEDEDE",
            }}
          >
            <span
              style={{
                background: "white",
                position: "relative",
                width: "100%",
                top: 6,
                height: 50,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              {" "}
              0{index + 1}
            </span>
          </div>
        </>
      ))}
    </div>
  );
};

export default ProgressBar;
