export const TourInfoJA = {
  tourInfoTitle1: "韓国観光情報選択",
  tourInfoBody1: "韓国の観光情報を選択してください。",
  tourInfoTitle2: "カテゴリを選択してください",
  tourInfoBody2: "ご覧になる観光カテゴリーをお選びください。",
  tourInfoTitle3: "カテゴリーリスト",
  tourInfoBody3: "カテゴリーリストをご覧ください。",
  tourInfoTitle4: "詳細情報を確認する",
  tourInfoBody4: "詳細ページで情報をご確認ください。",
};
export const CouponInfoJA = {
  couponInfoTitle1: "登録ボタン",
  couponInfoBody1: "登録ボタンを押してください。",
  couponInfoTitle2: "バーコード認識",
  couponInfoBody2: "アプリでバーコードを認識してください。",
  couponInfoTitle3: "クーポン使用完了",
  couponInfoBody3: "쿠クーポンが適用された状態です。",
};
export const KioskExchangeJA = {
  kioskExchangeTitle1: "全体同意選択",
  kioskExchangeBody1: "内容をご確認の上、全体の同意をチェックしてください。",
  kioskExchangeTitle2: "身分証明書の認識",
  kioskExchangeBody2: "パスポートまたは身分証明書を左上に入れてください。",
  kioskExchangeTitle3: "貨幣選択",
  kioskExchangeBody3: "両替する国の通貨を選択してください。",
  kioskExchangeTitle4: "両替可能な券種の確認",
  kioskExchangeBody4: "キオスクで両替できる券種を確認してください。",
  kioskExchangeTitle5: "貨幣投入",
  kioskExchangeBody5: "投入口に貨幣を一枚ずつ入れてください。",
  kioskExchangeTitle6: "完了",
  kioskExchangeBody6: "現金を取ってください。",
};
export const SignUpJA = {
  signUpTitle1: "サインアップ",
  signUpBody1: "継続するには、登録を選択してください",
  signUpTitle2: "約款同意",
  signUpBody2: "継続するために必要な条件に同意します",
  signUpTitle3: "電子メール検証",
  signUpBody3: "受信可能なメールアドレスを正しく入力してください",
  signUpTitle4: "電子メールの確認コードを入力します",
  signUpBody4: "確認コードをご確認の上、ご入力ください",
  signUpTitle5: "パスワードの設定",
  signUpBody5: "以下の条件に合わせてパスワードを設定してください",
  signUpTitle6: "セキュリティ PIN の設定",
  signUpBody6: "6桁のパスワードを設定してください",
  signUpTitle7: "登録が完了しました",
  signUpBody7: "登録が完了しました",
};

export const CouponAddJA = {
  couponAddTitle1: "クーポンコード",
  couponAddBody1: "クーポンコードからコードを入力してください",
  couponAddTitle2: "クーポンの追加",
  couponAddBody2: "クーポンの追加ボタンを入力して押してください",
  couponAddTitle3: "完了",
  couponAddBody3: "クーポン登録が完了しました",
  couponAddTitle4: "クーポンあり",
  couponAddBody4: "では、クーポンをご利用ください",
  settingTitle1: "管理の設定",
  settingBody1: "設定でパスワードの変更と会員退会を管理してください",
};
