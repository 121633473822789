import { useState, useEffect } from "react";

function useWebViewMessage() {
  const [userLanguage, setUserLanguage] = useState("");

  useEffect(() => {
    const handleMessage = (event) => {
      try {
        const receiveMessage = JSON.parse(event.data);
        if (receiveMessage.text === "GoToTOP") {
          window.scrollTo(0, 0);
        } else {
          setUserLanguage(receiveMessage.userLanguage);
        }
      } catch (error) {
        console.error("Failed to parse message:", error);
      }
    };

    // 이벤트 리스너 등록
    document.addEventListener("message", handleMessage);
    window.addEventListener("message", handleMessage);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      document.removeEventListener("message", handleMessage);
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return [userLanguage];
}

export default useWebViewMessage;
