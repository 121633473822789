import { useRef } from "react";
import ProgressBar from "../../component/ProgressBar";
import RanderComponent from "../../component/RanderComponent";
import useWebViewMessage from "../../hooks/useHandleMessage";
import useScrollStep from "../../hooks/useScrollStep";
function Setting() {
  const stepRef = useRef([]);
  const totalSteps = 1;
  const [userLanguage] = useWebViewMessage();
  const [currentStep, setCurrentStep] = useScrollStep(stepRef, totalSteps);

  const scrollToStep = (step) => {
    const element = stepRef.current[step - 1];
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    userLanguage !== "" && (
      <div>
        <ProgressBar
          currentStep={currentStep}
          totalSteps={totalSteps}
          handleStepClick={scrollToStep}
        />
        <RanderComponent
          totalNumber={totalSteps}
          ref={stepRef}
          textInfo={"setting"}
          imgInfo={"setting"}
          isKiosk={false}
          userLanguage={userLanguage}
        />
      </div>
    )
  );
}

export default Setting;
