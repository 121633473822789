import React from "react";
import DivComponent from "./DivComponent";
import TextTitle from "./TextTitle";
import string from "../locales";
const SubText = ({ text }) => {
  return <span style={{ padding: "10px 0px" }}>{text}</span>;
};

const renderImg = (imgURL, kiosk, folder) => {
  if (kiosk) {
    return <img width={"60%"} src={`/img/Kiosk/${folder}/${imgURL}.png`} />;
  }
  return <img width={"60%"} src={`/img/App/${folder}/${imgURL}.png`} />;
};

const RanderComponent = React.forwardRef(
  ({ totalNumber, textInfo, imgInfo, userLanguage, isKiosk = true }, ref) => {
    return Array(totalNumber)
      .fill(null)
      .map((_, index) => {
        return (
          <DivComponent
            marginBottom={index === totalNumber - 1 ? 50 : 0}
            ref={(el) => (ref.current[index] = el)}
          >
            <TextTitle
              string={`0${index + 1} ${
                string[userLanguage][`${textInfo}Title${index + 1}`]
              }`}
              kiosk={true}
            />
            <SubText
              text={`${string[userLanguage][`${textInfo}Body${index + 1}`]}`}
            />
            {renderImg(
              `${imgInfo}_${userLanguage}_${index + 1}`,
              isKiosk,
              imgInfo
            )}
          </DivComponent>
        );
      });
  }
);

export default RanderComponent;
