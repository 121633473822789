export const TourInfoCH = {
  tourInfoTitle1: "韩国旅游信息",
  tourInfoBody1: "选择韩国旅游信息.",
  tourInfoTitle2: "请选择类别",
  tourInfoBody2: "请选择您要参观的旅游类别。",
  tourInfoTitle3: "类别列表",
  tourInfoBody3: "请查看类别列表。",
  tourInfoTitle4: "查看详细信息",
  tourInfoBody4: "请在详细页面确认信息。",
};
export const CouponInfoCH = {
  couponInfoTitle1: "注册按钮",
  couponInfoBody1: "请按注册按钮。",
  couponInfoTitle2: "条形码识别",
  couponInfoBody2: "请在APP上识别条形码。",
  couponInfoTitle3: "优惠券使用完成",
  couponInfoBody3: "已使用优惠券。",
};
export const KioskExchangeCH = {
  kioskExchangeTitle1: "选择全部同意",
  kioskExchangeBody1: "请确认内容后在全体同意中打勾。",
  kioskExchangeTitle2: "身份证识别",
  kioskExchangeBody2: "请把护照或身份证放在左上角。",
  kioskExchangeTitle3: "选择货币",
  kioskExchangeBody3: "请选择要兑换的国家货币。",
  kioskExchangeTitle4: "确认可兑换券种",
  kioskExchangeBody4: "请确认一下在自助服务机可以兑换的券种。",
  kioskExchangeTitle5: "投入货币",
  kioskExchangeBody5: "请在投币口各放一张货币。",
  kioskExchangeTitle6: "完成",
  kioskExchangeBody6: "请带上现金",
};

export const SignUpCH = {
  signUpTitle1: "立即预订",
  signUpBody1: "请选择注册以继续",
  signUpTitle2: "同意条款",
  signUpBody2: "同意进行所需的条款",
  signUpTitle3: "电子邮件验证",
  signUpBody3: "请正确输入可接收的电子邮件地址",
  signUpTitle4: "输入电子邮件验证码",
  signUpBody4: "请检查验证码并输入",
  signUpTitle5: "设为首页 _ 加入收藏",
  signUpBody5: "请按照以下条件设置密码",
  signUpTitle6: "设置安全 PIN",
  signUpBody6: "请设置6位数的密码",
  signUpTitle7: "注册完成",
  signUpBody7: "您的注册已完成",
};

export const CouponAddCH = {
  couponAddTitle1: "优惠券代码",
  couponAddBody1: "请输入优惠券代码中的代码",
  couponAddTitle2: "添加优惠券",
  couponAddBody2: "请输入并按下添加优惠券按钮",
  couponAddTitle3: "完成",
  couponAddBody3: "优惠券注册成功",
  couponAddTitle4: "优惠券可用",
  couponAddBody4: "现在，请使用优惠券",
  settingTitle1: "设置管理",
  settingBody1: "请在设置中管理密码变更和会员注销",
};
