import { useState, useEffect, RefObject } from "react";

function useScrollStep(stepRef, totalNumber) {
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2 - 100;

      if (stepRef.current) {
        for (let i = 0; i < stepRef.current.length; i++) {
          const element = stepRef.current[i];
          if (element) {
            const elementHeight =
              i === totalNumber - 1
                ? element.offsetTop
                : element.offsetTop + 200;
            if (scrollPosition >= elementHeight) {
              setCurrentStep(i + 1);
            }
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [stepRef]);

  return [currentStep, setCurrentStep];
}
export default useScrollStep;
