export const TourInfoKO = {
  tourInfoTitle1: "한국 관광정보 선택",
  tourInfoBody1: "한국 관광정보를 선택해주세요.",
  tourInfoTitle2: "카테고리를 선택해주세요",
  tourInfoBody2: "둘러보실 관광 카테고리를 선택해주세요.",
  tourInfoTitle3: "카테고리 리스트",
  tourInfoBody3: "카테고리 리스트를 둘러보세요.",
  tourInfoTitle4: "상세정보 확인하기",
  tourInfoBody4: "상세 페이지에서 정보를 확인해보세요.",
};

export const CouponInfoKO = {
  couponInfoTitle1: "등록 버튼",
  couponInfoBody1: "등록버튼을 눌러주세요.",
  couponInfoTitle2: "바코드 인식",
  couponInfoBody2: "앱에서 바코드를 인식해주세요.",
  couponInfoTitle3: "쿠폰사용 완료",
  couponInfoBody3: "쿠폰이 적용된 상태 입니다.",
};

export const KioskExchangeKO = {
  kioskExchangeTitle1: "전체동의 선택",
  kioskExchangeBody1: "내용을 확인 후 전체동의에 체크해주세요.",
  kioskExchangeTitle2: "신분증인식",
  kioskExchangeBody2: "여권 또는 신분증을 좌측 상단으로 넣어주세요.",
  kioskExchangeTitle3: "화폐 선택",
  kioskExchangeBody3: "환전하실 국가 통화를 선택해주세요.",
  kioskExchangeTitle4: "환전가능한 권종 확인",
  kioskExchangeBody4: "키오스크에서 환전 가능한 권종을 확인해주세요.",
  kioskExchangeTitle5: "화폐 투입하기",
  kioskExchangeBody5: "투입구에 화폐를 한 장씩 넣어주세요.",
  kioskExchangeTitle6: "완료",
  kioskExchangeBody6: "현금을 챙겨주세요.",
};

export const SignUpKO = {
  signUpTitle1: "회원가입",
  signUpBody1: "회원가입을 선택하여 진행해주세요.",
  signUpTitle2: "약관동의",
  signUpBody2: "필수약관동의를 선택하여 진행해주세요.",
  signUpTitle3: "이메일인증",
  signUpBody3: "수신 가능한 이메일주소를 올바르게 입력해주세요.",
  signUpTitle4: "이메일 인증번호 입력",
  signUpBody4: "인증번호를 확인후 입력해주세요.",
  signUpTitle5: "비밀번호설정",
  signUpBody5: "아래 조건에 맞게 비밀번호를 설정해주세요.",
  signUpTitle6: "보안 바밀번호설정",
  signUpBody6: "6자리 비밀번호를 설정해주세요.",
  signUpTitle7: "회원 가입완료",
  signUpBody7: "회원가입이 완료 되었습니다.",
};

export const CouponAddKO = {
  couponAddTitle1: "등록 버튼",
  couponAddBody1: "등록버튼을 눌러주세요.",
  couponAddTitle2: "바코드 인식",
  couponAddBody2: "앱에서 바코드를 인식해주세요.",
  couponAddTitle3: "쿠폰사용 완료",
  couponAddBody3: "쿠폰이 적용된 상태 입니다.",
  couponAddTitle4: "쿠폰사용 완료",
  couponAddBody4: "쿠폰이 적용된 상태 입니다.",
  settingTitle1: "설정",
  settingBody1: "설정에서 비밀번호 변경과 회원탈퇴를 관리하세요.",
};
