export const TourInfoEN = {
  tourInfoTitle1: "Select Korea Tourism Information",
  tourInfoBody1: "Please select the tourist information of Korea.",
  tourInfoTitle2: "Please choose a category",
  tourInfoBody2: "Please select a tourism category to tour.",
  tourInfoTitle3: "Category list",
  tourInfoBody3: "Look around the list of categories.",
  tourInfoTitle4: "Check the details",
  tourInfoBody4: "Check out the information on the details page.",
};
export const CouponInfoEN = {
  couponInfoTitle1: "Registration button",
  couponInfoBody1: "Please press the registration button.",
  couponInfoTitle2: "Barcode recognition",
  couponInfoBody2: "Please recognize the barcode on the app.",
  couponInfoTitle3: "Coupon used complete",
  couponInfoBody3: "Coupons have been applied.",
};
export const KioskExchangeEN = {
  kioskExchangeTitle1: "Select All Agree",
  kioskExchangeBody1: "Please check the contents and check the full consent",
  kioskExchangeTitle2: "ID recognition",
  kioskExchangeBody2:
    "Please put your passport or ID in the upper left corner.",
  kioskExchangeTitle3: "Money selection",
  kioskExchangeBody3:
    "Please select the national currency you want to exchange.",
  kioskExchangeTitle4: "Check monetary unit",
  kioskExchangeBody4: "Please check the currencies supported by the kiosk.",
  kioskExchangeTitle5: "The injection of money",
  kioskExchangeBody5: "Please put a currency in the slot.",
  kioskExchangeTitle6: "Completion",
  kioskExchangeBody6: "Please take care of the cash.",
};
export const SignUpEN = {
  signUpTitle1: "Sign up",
  signUpBody1: "Please select sign-up to proceed.",
  signUpTitle2: "Agreement on Terms and Conditions",
  signUpBody2: "Agree to the required terms to proceed.",
  signUpTitle3: "Email verification",
  signUpBody3: "Please enter a valid email address.",
  signUpTitle4: "Enter the email verification code",
  signUpBody4: "Please check the verification code and enter it.",
  signUpTitle5: "Set password",
  signUpBody5: "Please set the password according to the conditions below.",
  signUpTitle6: "Set security PIN",
  signUpBody6: "Please set a 6-digit password.",
  signUpTitle7: "Registration complete",
  signUpBody7: "Your registration is complete.",
};

export const CouponAddEN = {
  couponAddTitle1: "Coupon code",
  couponAddBody1: "Please enter the code from the coupon code",
  couponAddTitle2: "Add Coupons",
  couponAddBody2: "Please enter and press the Add Coupon button",
  couponAddTitle3: "Completed",
  couponAddBody3: "Coupon registration completed.",
  couponAddTitle4: "Coupon available",
  couponAddBody4: "Now, please use the coupon",
  settingTitle1: "Setting Management",
  settingBody1:
    "Manage password changes and\nmembership withdrawal in settings.",
};
