import {
  TourInfoKO,
  CouponAddKO,
  KioskExchangeKO,
  CouponInfoKO,
  SignUpKO,
} from "./ko";
import {
  TourInfoCH,
  CouponAddCH,
  CouponInfoCH,
  KioskExchangeCH,
  SignUpCH,
} from "./ch";
import {
  TourInfoEN,
  CouponAddEN,
  CouponInfoEN,
  KioskExchangeEN,
  SignUpEN,
} from "./en";
import {
  TourInfoJA,
  CouponAddJA,
  CouponInfoJA,
  KioskExchangeJA,
  SignUpJA,
} from "./ja";
import {
  TourInfoTW,
  CouponAddTW,
  CouponInfoTW,
  KioskExchangeTW,
  SignUpTW,
} from "./tw";
import {
  TourInfoTH,
  CouponAddTH,
  CouponInfoTH,
  KioskExchangeTH,
  SignUpTH,
} from "./th";

const string = {
  KR: {
    ...TourInfoKO,
    ...CouponAddKO,
    ...KioskExchangeKO,
    ...CouponInfoKO,
    ...SignUpKO,
  },
  CN: {
    ...TourInfoCH,
    ...CouponAddCH,
    ...CouponInfoCH,
    ...KioskExchangeCH,
    ...SignUpCH,
  },
  EN: {
    ...TourInfoEN,
    ...CouponAddEN,
    ...CouponInfoEN,
    ...KioskExchangeEN,
    ...SignUpEN,
  },
  JP: {
    ...TourInfoJA,
    ...CouponAddJA,
    ...CouponInfoJA,
    ...KioskExchangeJA,
    ...SignUpJA,
  },
  TW: {
    ...TourInfoTW,
    ...CouponAddTW,
    ...CouponInfoTW,
    ...KioskExchangeTW,
    ...SignUpTW,
  },
  TH: {
    ...TourInfoTH,
    ...CouponAddTH,
    ...CouponInfoTH,
    ...KioskExchangeTH,
    ...SignUpTH,
  },
};

export default string;
