import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TourInfo from "./pages/kiosk/TourInfo";
import KioskExchange from "./pages/kiosk/KioskExchange";
import CouponInfo from "./pages/kiosk/CouponInfo";
import SignUpInfo from "./pages/app/SignUp";
import CouponAdd from "./pages/app/CouponAdd";
import ExchangeInfo from "./pages/ExchangeInfo";
import Setting from "./pages/app/Setting";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ExchangeInfo />} />
        <Route path="/kiosk/tour" element={<TourInfo />} />
        <Route path="/kiosk/exchange" element={<KioskExchange />} />
        <Route path="/kiosk/coupon" element={<CouponInfo />} />
        <Route path="/app/signup" element={<SignUpInfo />} />
        <Route path="/app/coupon" element={<CouponAdd />} />
        <Route path="/app/exchange" element={<ExchangeInfo />} />
        <Route path="/app/setting" element={<Setting />} />
      </Routes>
    </Router>
  );
}

export default App;
