export default function TextTitle({ string, kiosk, marginTop = 50 }) {
  if (kiosk) {
    return (
      <span
        style={{
          marginTop: marginTop,
          fontWeight: 800,
          fontSize: 14,
          color: "#FFC028",
          border: "1px solid #FFC028",
          padding: "5px 10px",
          borderRadius: "28px",
        }}
      >
        {string}
      </span>
    );
  }
  return (
    <span
      style={{
        marginTop: 50,
        fontWeight: 800,
        fontSize: 14,
        marginBottom: 20,
        color: "#FFC028",
        border: "1px solid #FFC028",
        padding: "5px 10px",
        borderRadius: "28px",
      }}
    >
      {string}
    </span>
  );
}
