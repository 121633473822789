export const TourInfoTW = {
  tourInfoTitle1: "選擇韓國旅遊信息",
  tourInfoBody1: "請選擇韓國旅遊信息。",
  tourInfoTitle2: "請選擇類別",
  tourInfoBody2: "請選擇您要參觀的旅遊類別。",
  tourInfoTitle3: "類別列表",
  tourInfoBody3: "請查看類別列表。",
  tourInfoTitle4: "查看詳細信息",
  tourInfoBody4: "請在詳細頁面確認信息。",
};
export const CouponInfoTW = {
  couponInfoTitle1: "注册按钮",
  couponInfoBody1: "請按註冊按鈕。",
  couponInfoTitle2: "條形碼識別",
  couponInfoBody2: "請在APP上識別條形碼。",
  couponInfoTitle3: "優惠券使用完成",
  couponInfoBody3: "已使用優惠券。",
};
export const KioskExchangeTW = {
  kioskExchangeTitle1: "選擇全部同意",
  kioskExchangeBody1: "請確認內容後在全體同意中打勾。",
  kioskExchangeTitle2: "身份證識別",
  kioskExchangeBody2: "請把護照或身份證放在左上角。",
  kioskExchangeTitle3: "選擇貨幣",
  kioskExchangeBody3: "請選擇要兌換的國家貨幣。",
  kioskExchangeTitle4: "確認可兌換券種",
  kioskExchangeBody4: "請確認一下在自助服務機可以兌換的券種。",
  kioskExchangeTitle5: "投入貨幣",
  kioskExchangeBody5: "請在投幣口各放一張貨幣。",
  kioskExchangeTitle6: "完成",
  kioskExchangeBody6: "請帶上現金",
};
export const SignUpTW = {
  signUpTitle1: "立即預訂",
  signUpBody1: "請選擇註冊以繼續",
  signUpTitle2: "同意條款",
  signUpBody2: "同意進行所需的條款",
  signUpTitle3: "電子郵件驗證",
  signUpBody3: "환請正確輸入可接收的電子郵件地址",
  signUpTitle4: "輸入電子郵件驗證碼",
  signUpBody4: "請檢查驗證碼並輸入",
  signUpTitle5: "設爲首頁 _ 加入收藏",
  signUpBody5: "請按照以下條件設置密碼",
  signUpTitle6: "設置安全 PIN",
  signUpBody6: "請設置6位數的密碼",
  signUpTitle7: "註冊完成",
  signUpBody7: "您的註冊已完成",
};

export const CouponAddTW = {
  couponAddTitle1: "優惠券代碼",
  couponAddBody1: "請輸入優惠券代碼中的代碼",
  couponAddTitle2: "添加優惠券",
  couponAddBody2: "請輸入並按下添加優惠券按鈕",
  couponAddTitle3: "完成",
  couponAddBody3: "優惠券註冊成功",
  couponAddTitle4: "優惠券可用",
  couponAddBody4: "現在，請使用優惠券",
  settingTitle1: "設置管理",
  settingBody1: "請在設置中管理密碼變更和會員註銷",
};
