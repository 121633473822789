export const TourInfoTH = {
  tourInfoTitle1: "เลือกข้อมูลการท่องเที่ยวเกาหลี",
  tourInfoBody1: "กรุณาเลือกข้อมูลการท่องเที่ยวเกาหลีค่ะ",
  tourInfoTitle2: "โปรดเลือกหมวดหมู่",
  tourInfoBody2: "กรุณาเลือกหมวดหมู่การท่องเที่ยวที่จะดูค่ะ",
  tourInfoTitle3: "รายชื่อหมวดหมู่",
  tourInfoBody3: "ลองดูรายชื่อหมวดหมู่",
  tourInfoTitle4: "ตรวจสอบรายละเอียด",
  tourInfoBody4: "กรุณาตรวจสอบข้อมูลในหน้ารายละเอียดค่ะ",
};
export const CouponInfoTH = {
  couponInfoTitle1: "ปุ่มลงทะเบียน",
  couponInfoBody1: "กรุณากดปุ่มลงทะเบียนค่ะ",
  couponInfoTitle2: "การจดจำบาร์โค้ด",
  couponInfoBody2: "แท็กบาร์โค้ดในแอปค่ะ",
  couponInfoTitle3: "ใช้คูปองเรียบร้อยแล้ว",
  couponInfoBody3: "กำลังใช้คูปองอยู่ค่ะ",
};
export const KioskExchangeTH = {
  kioskExchangeTitle1: "การเลือกความยินยอมทั้งหมด",
  kioskExchangeBody1:
    "หลังจากตรวจสอบเนื้อหาแล้ว กรุณาตรวจสอบความเห็นชอบทั้งหมดค่ะ",
  kioskExchangeTitle2: "การจดบัตรประชาชน",
  kioskExchangeBody2:
    "กรุณาใส่หนังสือเดินทางหรือบัตรประจำตัวไว้ที่ด้านบนซ้ายค่ะ",
  kioskExchangeTitle3: "การเลือกเงินตรา",
  kioskExchangeBody3: "กรุณาเลือกสกุลเงินของประเทศที่จะแลกเงิน",
  kioskExchangeTitle4: "ยืนยันประเภทตั๋วแลกเงินได้",
  kioskExchangeBody4: "โปรดตรวจสอบประเภทที่สามารถแลกเปลี่ยนได้ที่ Kiosk",
  kioskExchangeTitle5: "การใส่เงิน",
  kioskExchangeBody5: "กรุณาใส่เงินลงในช่องใส่เงินทีละใบค่ะ",
  kioskExchangeTitle6: "เสร็จสมบูรณ์",
  kioskExchangeBody6: "ช่วยเอาเงินสดให้หน่อยค่ะ",
};
export const SignUpTH = {
  signUpTitle1: "ลงชื่อสมัคร",
  signUpBody1: "โปรดเลือกการลงทะเบียนเพื่อดำเนินการต่อ",
  signUpTitle2: "ข้อตกลงและเงื่อนไข",
  signUpBody2: "ตกลงตามเงื่อนไขที่จำเป็นเพื่อดำเนินการต่อ",
  signUpTitle3: "การตรวจสอบอีเมล",
  signUpBody3: "กรุณากรอกที่อยู่อีเมล์์ที่สามารถรับได้อย่างถูกต้องค่ะ",
  signUpTitle4: "ป้อนรหัสยืนยันอีเมล",
  signUpBody4: "โปรดตรวจสอบรหัสยืนยันและป้อนมัน",
  signUpTitle5: "ตั้งรหัสผ่าน",
  signUpBody5: "กรุณาตั้งรหัสผ่านให้ตรงกับเงื่อนไขด้านล่างค่ะ",
  signUpTitle6: "ตั้งค่า PIN ความปลอดภัย",
  signUpBody6: "กรุณาตั้งรหัสผ่าน 6 หลักค่ะ",
  signUpTitle7: "การลงทะเบียนเสร็จสมบูรณ์",
  signUpBody7: "การลงทะเบียนของคุณเสร็จสมบูรณ์แล้ว",
};

export const CouponAddTH = {
  couponAddTitle1: "รหัสคูปอง",
  couponAddBody1: "โปรดป้อนรหัสจากรหัสคูปอง",
  couponAddTitle2: "เพิ่มคูปอง",
  couponAddBody2: "โปรดป้อนและกดปุ่มเพิ่มคูปอง",
  couponAddTitle3: "เสร็จสมบูรณ์แล้ว",
  couponAddBody3: "การลงทะเบียนคูปองเสร็จสมบูรณ์แล้ว",
  couponAddTitle4: "ใช้คูปองได้",
  couponAddBody4: "ตอนนี้ กรุณาใช้คูปอง",
  settingTitle1: "ตั้งค่าการจัดการ",
  settingBody1: "จัดการการเปลี่ยนแปลงรหัสผ่านและการถอนสมาชิกในการตั้งค่า",
};
